/* --gray900: #44475b;
--azureblue: #0082fb;
--grey: #465a69 
--#4f31df

xs, extra-small: 0px
sm, small: 600px
md, medium: 900px
lg, large: 1200px
xl, extra-large: 1536px

*/

.css-1szbw9v-MuiGrid-root {
  padding-top: 0rem !important;
  padding-right: 0rem !important;
}

.css-mhc70k-MuiGrid-root > .MuiGrid-item {
  padding-right: 0.5rem;
}

a {
  -webkit-tap-highlight-color: transparent;
}

a:link,
a:visited {
  text-decoration: none;
}

.nav-btn {
  position: relative;
  display: inline-block;
  height: 3rem;
  align-content: center;
  color: #44475b;
}

.nav-btn:link,
.nav-btn:visited {
  border-bottom: 0.2rem solid #fff;
}

.nav-btn:hover,
.nav-btn:active {
  color: #0082fb;
}

.nav-btn::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0.1rem;
  background-color: #0082fb;
  bottom: -0.2rem;
  left: 0;
  transition: width 0.3s ease;
}

.nav-btn:hover::after {
  width: 100%;
}

.btn-active {
  color: #0082fb;
  border-width: 0rem 0rem 0.2rem 0rem;
  border-color: #fff #fff #0082fb #fff;
}

ul,
ol {
  padding-left: 1rem;
  margin: 0.5rem 0;
  line-height: 1.5;
}

li {
  margin-bottom: 0.15rem;
}

.home-pitch-1 {
  font-size: 1.2rem;
  color: #44475b;
  font-weight: 450;
  font-family: "Quicksand", "Roboto", "Arial", "sans-serif";
}

.plot_box {
  width: 100%;
  height: auto;
  /* border: "1px solid #ccc", // Optional: Adds a border for visual clarity */
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 1.5rem 0;
}

.prompt_box {
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 1.5rem 0;
  /* rgba(0, 0, 0, 0.1) 0 0.5rem 2rem; */
}

.plot_object {
  width: 100%;
  min-height: 32rem;
}

.logo-company_name {
  display: flex;
  align-items: center;
}

.css-1fojr6f {
  margin-left: 0rem !important;
}

.logo {
  width: 2.5rem; /* Adjust size as needed */
  height: auto;
  margin-right: 0.625rem; /* Space between logo and name */
}

.company-name {
  color: #0082fb;
}

.MuiButton-endIcon,
.MuiButton-startIcon {
  margin: 0 !important; /* Ensure margin is set to 0 */
}

.download-button,
.share-button,
.fullscreen-button {
  position: relative; /* Allows positioning of the text */
  padding: 0;
}

.download-button::after,
.share-button::after,
.fullscreen-button::after {
  position: absolute;
  bottom: -1.75rem; /* Position the text below the button */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0; /* Hide the text by default */
  background-color: rgba(0, 0, 0, 0.75);
  padding: 0.1rem 0.3rem;
  border-radius: 0.25rem;
  border: none;
  font-size: 0.875rem;
  color: #fff;
  /* border: 1px solid #ccc; */
  white-space: nowrap; /* Prevent the text from wrapping */
  pointer-events: none; /* Prevent the text from affecting button clicks */
  transition: opacity 0.3s ease; /* Smooth transition effect */
  text-transform: none; /* Ensure sentence case */
  font-weight: 400;
  font-size: 0.75rem;
}

.download-button::after {
  content: "Download"; /* Text to display on hover */
}
.share-button::after {
  content: "Share"; /* Text to display on hover */
}
.fullscreen-button::after {
  content: "View in fullscreen"; /* Text to display on hover */
}

.download-button:hover::after,
.share-button:hover::after,
.fullscreen-button:hover::after {
  opacity: 1; /* Show the text on hover */
}

/* Spinner container */
.spinner-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

/* Spinner */
.spinner {
  border: 0.75rem solid rgba(0, 0, 0, 0.1);
  border-left: 0.75rem solid #3498db;
  border-radius: 100%;
  width: 6rem;
  height: 6rem;
  animation: spin 0.66s linear infinite;
}

/* Overlay */
.overlay-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 9998; /* Slightly lower than the spinner */
}

/* Spin animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.section-border {
  display: block;
  border-bottom: 0.05rem solid #d1d5db;
  /* border-bottom-style: dashed; */
  margin: 0 4rem;
  padding-top: 1rem;
}

.site-line {
  display: block;
  background-image: linear-gradient(to right, #4f31df, #00c6ff);
  height: 0.2rem;
  max-width: 120rem;
  border: 0;
  border-radius: 1rem;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.dot-1 {
  animation: blink 1.5s infinite;
}

.dot-2 {
  animation: blink 1.5s infinite 0.5s;
}

.dot-3 {
  animation: blink 1.5s infinite 1s;
}
