.chip-scroll-container {
  position: relative;
  overflow: hidden; /* Ensure the gradient doesn't overflow */
}

.chip-scroll-container::before,
.chip-scroll-container::after {
  content: "";
  position: absolute;
  top: 0;
  width: 50px; /* Adjust width as needed */
  height: 100%;
  z-index: 1; /* Ensure it appears above the background */
}

.chip-scroll-container::before {
  left: 0;
  background: linear-gradient(
    to right,
    white,
    rgba(255, 255, 255, 0)
  ); /* Adjust colors as needed */
}

.chip-scroll-container::after {
  right: 0;
  background: linear-gradient(
    to left,
    white,
    rgba(255, 255, 255, 0)
  ); /* Adjust colors as needed */
}

.chip-scroll {
  display: flex;
  width: max-content; /* Allow the content to expand */
  animation: scroll-left 210s linear infinite; /* Continuous scrolling */
}

.chip-wrapper {
  padding: 0 10px; /* Space between chips */
}

@keyframes scroll-left {
  from {
    transform: translateX(0); /* Start from the right */
  }
  to {
    transform: translateX(-100%); /* Move to the left */
  }
}
