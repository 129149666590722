@media (max-width: 1200px) {
  html {
    font-size: 14px;
  }
}
/* 
@media (max-width: 600px) {
  html {
    font-size: 12px;
  }
} */
